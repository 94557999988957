import { addons } from '@storybook/manager-api';
import { create } from '@storybook/theming/create';
import logo from '../src/assets/images/hpe-athonet-logo.png';

addons.setConfig({
  theme: create({
    base: 'light',
    brandTitle: 'Athonet UI Lib',
    brandUrl: '',
    brandImage: logo,
    brandTarget: '_self',
  }),
});
